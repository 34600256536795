export class SideNavConfig {
  tabInfoList: TabInformation[] = [
    {
      name: TAB.CASE_INFO,
      isDefault: true,
      isEnable: true,
      analyticsPageName: 'eticket - case information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      isFormBasedTab: true
    },
    {
      name: TAB.ATTESTATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - attestation',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      isFormBasedTab: true
    },
    {
      name: TAB.PRIMARY_INSURED,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - primary insured',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.COVERAGE_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - coverage information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.RIDERS_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - riders',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.OWNER_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - owner information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.TRUST_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - trust info',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.ELECTRONIC_FUND_TRANSFER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - eft',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.LTC_INSURANCE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - ltc existing pending insurance',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.TEMPORARY_LIFE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - temporary life insurance agreement',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      excludeState: ['KS'],
      isFormBasedTab: true
    },
    {
      name: TAB.EXISTING_INSURANCE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - existing insurance',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.WA_REPLACEMENT,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - wa replacements',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.PROPOSED_INSURED_B,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - proposed insured b',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.WEALTHPRESERVESIUL2022, ProductCodes.SVULONE2021, ProductCodes.ASSETEDGESVUL],
      isFormBasedTab: true
    },
    {
      name: TAB.EXISTING_INSURANCE_FOR_PROPOSED_B,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - existing insurance proposed insured b',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.WEALTHPRESERVESIUL2022, ProductCodes.SVULONE2021, ProductCodes.ASSETEDGESVUL],
      isFormBasedTab: true
    },
    {
      name: TAB.WA_REPLACEMENT_PROPOSED_B,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - wa replacements proposed b',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
    },
    {
      name: TAB.FUND_ALLOCATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - fund allocation',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.ASSETEDGEVUL2020, ProductCodes.ASSETEDGEVUL2022,
      ProductCodes.ASSETEDGEVUL2025, ProductCodes.ASSETEDGEEXEC2020, ProductCodes.ASSETEDGESVUL],
      isFormBasedTab: true
    },
    {
      name: TAB.VUL_FUND_ALLOCATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - fund allocation',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.VULONE2021, ProductCodes.SVULONE2021],
      isFormBasedTab: true
    },
    {
      name: TAB.DOLLAR_COST_AVERAGING,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - dollar cost averaging',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.VULONE2021, ProductCodes.SVULONE2021],
      isFormBasedTab: true
    },
    {
      name: TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - maturing indexed segment allocation',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - transfer allocation charges authorization',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      displayConditionBasedOnProductCode: [ProductCodes.ASSETEDGEVUL2020, ProductCodes.ASSETEDGEEXEC2020,
      ProductCodes.ASSETEDGEVUL2022, ProductCodes.ASSETEDGEVUL2025, ProductCodes.ASSETEDGESVUL, ProductCodes.VULONE2021, ProductCodes.SVULONE2021],
      isFormBasedTab: true
    },
    {
      name: TAB.SUITABILITY_REVIEW,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - suitability review',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.VUL],
      isFormBasedTab: true
    },
    {
      name: TAB.ILLUSTRATION_INFORMATION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - illustration information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.AGENT_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - agent information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.AGENT_INFO_CONT,
      analyticsPageName: 'eticket - agent information continue',
      isDefault: false,
      isEnable: false,
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.ADDITIONAL_INFO,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - additional information',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: true
    },
    {
      name: TAB.APPLICATION_SUMMARY,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - application summary',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: false
    },
    {
      name: TAB.VALIDATE_AND_LOCK,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - validate and lock',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [ProductType.TERM, ProductType.VUL, ProductType.IUL],
      isFormBasedTab: false
    },
    {
      name: TAB.SIGNATURE_METHOD,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - signature method',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.VOICE_TERM_OF_USE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - voice term of use',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.VOICE_CONFIRM_THANK_YOU,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - voice thank you',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: false
    },
    {
      name: TAB.AGENT_INSTRUCTION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - agent instruction',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.E_SIGNATURE_INSTRUCTION,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - e-signature instruction',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_PI,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page primary insured',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_INSURED_OWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page insured owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_ADDITIONAL_INSURED,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page additional insured',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_INSURED_B,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page additional insured co-owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_OWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page co-owner',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER2,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page co-owner2',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_COOWNER3,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page co-owner3',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.CONSENT_PAGE_AGENT,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - consent page agent',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: true
    },
    {
      name: TAB.ELECTRONIC_SIGNATURE,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - electronic signature',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: false
    },
    {
      name: TAB.REVIEW_AND_SUBMIT,
      isDefault: false,
      isEnable: false,
      analyticsPageName: 'eticket - submit case',
      status: {
        isLocked: false,
        visited: false,
        errorExists: false,
      },
      displayConditionBasedOnProduct: [],
      isFormBasedTab: false
    }
  ];
}

export const TAB = {
  CASE_INFO: 'Case information',
  COVERAGE_INFO: 'Coverage information',
  PRIMARY_INSURED: 'Primary insured information',
  ATTESTATION: 'Attestation',
  RIDERS_INFO: 'Riders information',
  OWNER_INFO: 'Owner information',
  TEMPORARY_LIFE: 'Temporary life insurance agreement',
  EXISTING_INSURANCE: 'Existing insurance',
  AGENT_INFO: 'Agent information',
  AGENT_INFO_CONT: 'Agent information continued',
  ADDITIONAL_INFO: 'Additional information',
  APPLICATION_SUMMARY: 'Summary information',
  VALIDATE_AND_LOCK: 'Validate and lock',
  VOICE_CONFIRM_THANK_YOU: 'Thank you',
  SIGNATURE_METHOD: 'Signature method',
  VOICE_TERM_OF_USE: 'Term of use',
  ELECTRONIC_FUND_TRANSFER: 'EFT information',
  ELECTRONIC_SIGNATURE: 'Electronic signature',
  AGENT_INSTRUCTION: 'Agent instructions',
  E_SIGNATURE_INSTRUCTION: 'eSignature instructions',
  FUND_ALLOCATION: 'Fund allocation',
  VUL_FUND_ALLOCATION: 'Fund allocation ',
  DOLLAR_COST_AVERAGING: 'Dollar cost averaging',
  MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION: 'Maturing indexed account segment allocation',
  TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION: 'Transfer/allocation/charges authorization',
  SUITABILITY_REVIEW: 'Suitability review',
  TRUST_INFO: 'Trust information',
  PROPOSED_INSURED_B: 'Proposed insured B information',
  EXISTING_INSURANCE_FOR_PROPOSED_B: 'Existing insurance for proposed insured B',
  WA_REPLACEMENT: 'WA replacements',
  WA_REPLACEMENT_PROPOSED_B: 'WA replacements - insured B',
  LTC_INSURANCE: 'LTC - existing/pending insurance',
  ILLUSTRATION_INFORMATION: 'Illustration information',
  CONSENT_PAGE_PI: 'Term of use - primary insured',
  CONSENT_PAGE_INSURED_OWNER: 'Term of use - insured owner',
  CONSENT_PAGE_ADDITIONAL_INSURED: 'Term of use - additional insured',
  CONSENT_PAGE_INSURED_B: 'Term of use - additional insured co-owner',
  CONSENT_PAGE_OWNER: 'Term of use - owner',
  CONSENT_PAGE_COOWNER: 'Term of use - co-owner',
  CONSENT_PAGE_COOWNER2: 'Term of use - co-owner 2',
  CONSENT_PAGE_COOWNER3: 'Term of use - co-owner 3',
  CONSENT_PAGE_AGENT: 'Term of use - agent',
  REVIEW_AND_SUBMIT: 'Submit case'
};

export class TabInformation {
  name: string;
  isDefault: boolean;
  isEnable: boolean;
  analyticsPageName: string;
  displayConditionBasedOnProduct?: string[];
  displayConditionBasedOnProductCode?: string[];
  status: TabStatus;
  includeState?: string[];
  excludeState?: string[];
  isFormBasedTab?: boolean;
}

export class TabStatus {
  visited: boolean;
  errorExists: boolean;
  isLocked: boolean;
}

export const ProductType = {
  TERM: 'term',
  VUL: 'variable universal life',
  IUL: 'indexed universal life',
  LONG_TERM_CARE: 'Long Term Care'
};

export const ProductCodes = {
  TERMACCEL: 'termaccel',
  LIFEELEMENT: 'lincoln lifeelements level term',
  LIFEELEMENT2024: 'lincoln lifeelements level term 2024',
  // IUL Products
  WEALTHACCELERATE2022: 'lincoln wealthaccelerate indexed ul 2022',
  WEALTHPRESERVESIUL2022: 'lincoln wealthpreserve 2 survivorship iul 2022',
  WEALTHPRESERVE2IUL2020: 'lincoln wealthpreserve 2 indexed ul 2020',
  WEALTHACCUMULATEIUL2020: 'lincoln wealthaccumulate 2 indexed ul 2020',
  // VUL Products
  ASSETEDGEVUL2022: 'lincoln assetedge vul 2022',
  ASSETEDGEVUL2025: 'lincoln assetedge vul 2025',
  SVULONE2021: 'lincoln svulone 2021',
  VULONE2021: 'lincoln vulone 2021',
  ASSETEDGEVUL2020: 'lincoln assetedge vul 2020',
  ASSETEDGESVUL: 'lincoln assetedge svul',
  ASSETEDGEEXEC2020: 'lincoln assetedge exec vul 2020',

  MGMA22: 'lincoln moneyguard market advantage 2022 (full application)',
  MONEYGUARD: 'moneyguard',
};
