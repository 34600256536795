import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, FundAllocationConst, Message } from 'src/config/constants';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-fund-allocation',
  templateUrl: './fund-allocation.component.html',
  styleUrls: ['./fund-allocation.component.scss']
})
export class FundAllocationComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() dcaInfoUpdated: EventEmitter<string> = new EventEmitter();
  @Output() isMIRequired: EventEmitter<string> = new EventEmitter();

  selectedAllocation: string;
  selectedPortfolio: string;

  optionIndex = -1;
  fundAllocationData: Question[] = [];
  fundAllocationData2: Question[] = [];
  fundAllocationDataCombined: Question[] = [];
  fundAllocationForm: FormGroup;

  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  alldropDownOptions = {};

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  ErrMessage = ErrorMessage;
  Message = Message;

  isDCARequired: string;
  totalFundPercentage = 0;
  openAccordion = false;
  isDollarCostAveraingSelected: boolean;
  showDollarCostFundError: boolean;

  passivePortFolioDataList = [];
  passivePortFolioQuesId: string;

  // for storing field ids for funds
  fixedAccoundFieldId: string;
  govtFundFieldId: string;

  // save previous value
  govtFundExistingValue: number;
  fixedFundExistingValue: number;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private productService: ProductDetailsService,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.fundAllocationForm);
      this.defaultPageLoadService.disableFormIfLocked(this.fundAllocationForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();

      this.getPortFolioFundValues();
      this.initializePortfolio();
      this.calculatetotalFundPercentage();

      this.checkForOptionalTabs();
    }
  }

  onAllocationRadioChange(selectedAllocation: string): void {
    this.selectedAllocation = selectedAllocation;
    console.log(this.selectedAllocation);
    this.setOptionIndexForPortfolioTable();
  }

  private buildFormData(): any {
    this.fundAllocationData = this.questionsData.pages[0].questions;
    this.fundAllocationData2 = this.questionsData.pages[1].questions;
    this.fundAllocationDataCombined = this.fundAllocationData.concat(this.fundAllocationData2);
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.fundAllocationData
    );
    const form = this.formgeneratorService.createFormControls(
      this.fundAllocationData.concat(this.fundAllocationData2)
    );
    this.fundAllocationForm = this.fb.group(form);
    this.updateFieldIdsForFunds();
    this.updateExistingValueForFunds();
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.calculatetotalFundPercentage();
      this.checkForDollarCostFundError();
      this.checkFormStatus();
    }, 200);
    this.fundAllocationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.calculatetotalFundPercentage();
        this.checkForDollarCostFundError();
        this.checkFormStatus();
      }, 200);
    });
  }

  private dcaInfoUpdate(data: Question): void {
    if (data.xmlTag === FundAllocationConst.dollarCostAverage) {
      this.emitDcaInfoUpdatedInfo(data.fieldId);
    }
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid && this.totalFundPercentage === 100 && !this.showDollarCostFundError,
      formHasNoErrors: this.formHasNoErrors,
      form: this.fundAllocationForm,
      data: null,
    };
    for (const data of (this.fundAllocationData.concat(this.fundAllocationData2))) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.saveFundsForDCAPage();
    this.emitStatus();
  }

  onFundRadioChange(data): any {
    this.updateFundAnswersOnHidden(data);
    this.dcaInfoUpdate(data);
  }

  handleFundSelectionChange(data, _ix?): any {
    this.updateFundAnswersOnHidden(data);
  }

  addFundCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updateFundAnswersOnHidden(data): any {
    const value = this.fundAllocationForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.fundAllocationForm.get(key)?.markAsUntouched();
      }
      this.fundAllocationForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.fundAllocationForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckFundInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  emitStatus(): void {
    const fundAllocationObj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'fundAllocationForm',
      gridForm: true,
      isFormChange: this.fundAllocationForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.fundAllocationForm),
      fundList: this.selectedAllocation ? this.passivePortFolioDataList : [],
      allocationType: this.selectedAllocation,
    };
    this.checkForMITab();
    this.formStatus.emit(fundAllocationObj);
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  resetForm(quesText: string): void {
    if (quesText?.toLowerCase().indexOf('fund') > -1) {
      this.resetFundFields();
    } else {
      this.resetFundForm();
    }
    this.defaultPageLoadService.logButtonClick('reset all selections');
  }

  private resetFundForm(): void {
    // reset model portfolio section
    this.selectedPortfolio = '';
    this.reinitializeModalPortfolioVal();
    this.fundAllocationForm.get(this.getFieldIdByXmlTag(FundAllocationConst.passivePortfolioSelection))?.reset();
    this.updateFundSelectionFieldStatus(false);
  }

  private resetFundFields(): void {
    this.updateFundSelectionFieldStatus(false, true);
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private calculatetotalFundPercentage(): void {
    this.totalFundPercentage = 0;
    if (!this.selectedAllocation) {
      Object.keys(this.fundAllocationForm.controls).forEach(key => {
        if (key.indexOf(FundAllocationConst.fundQuesXmlTag) > -1) {
          this.updatePercentage(key);
          this.totalFundPercentage = this.totalFundPercentage + +(this.fundAllocationForm.get(key).value);
        }
      });
    } else {
      this.totalFundPercentage = 100;
    }
  }

  private updatePercentage(key: string): void {
    if (+(this.fundAllocationForm.get(key)?.value > 100)) {
      this.fundAllocationForm.get(key).setValue('100');
    }
  }

  toggleAccordionStatus(): void {
    this.openAccordion = !this.openAccordion;
  }

  private checkForDollarCostFundError(): void {
    const productSelected = this.productService.getProductName()?.toLowerCase();
    if (productSelected === ProductCodes.ASSETEDGEVUL2020 || productSelected === ProductCodes.ASSETEDGEEXEC2020 ||
      productSelected === ProductCodes.ASSETEDGEVUL2022 || productSelected === ProductCodes.ASSETEDGEVUL2025 ||
      productSelected === ProductCodes.ASSETEDGESVUL) {
      this.isDollarCostAveraingSelected = this.fundAllocationForm.get(this.getFieldIdByXmlTag(
        FundAllocationConst.dollarCostAverage))?.value?.toLowerCase() === 'y';
      if (this.isDollarCostAveraingSelected && this.totalFundPercentage === 100) {
        this.showDollarCostFundError = !(+(this.fundAllocationForm.get(this.fixedAccoundFieldId)?.value) > 0
          || +(this.fundAllocationForm.get(this.govtFundFieldId)?.value) > 0);
      } else {
        this.showDollarCostFundError = false;
      }
    }
  }

  onPortFolioSelection(selectedPortfolio: string): void {
    this.totalFundPercentage = 0;
    this.selectedPortfolio = selectedPortfolio;
    this.reinitializeModalPortfolioVal();
    this.disableIndividualFundSelectOption();
  }

  private updateFieldIdsForFunds(): void {
    this.fixedAccoundFieldId = this.fundAllocationData.filter(ques => {
      return ques.notes === 'FundSelect-105';
    })[0]?.fieldId;
    this.govtFundFieldId = this.fundAllocationData2.filter(ques => {
      return ques.notes === 'FundSelect-876';
    })[0]?.fieldId;
  }

  private isDCAPageApplicableForProduct(productSelected: string): boolean {
    const productCodeList = [ProductCodes.ASSETEDGEEXEC2020, ProductCodes.ASSETEDGEVUL2020,
    ProductCodes.ASSETEDGEVUL2022, ProductCodes.ASSETEDGEVUL2025, ProductCodes.ASSETEDGESVUL];
    return (productCodeList.includes(productSelected));
  }

  private saveFundsForDCAPage(): void {
    this.userService.setFixedAccountAndGovtFund(+(this.fundAllocationForm.get(this.fixedAccoundFieldId)?.value),
      +(this.fundAllocationForm.get(this.govtFundFieldId)?.value));
    if (this.govtFundExistingValue !== +(this.fundAllocationForm.get(this.govtFundFieldId)?.value) ||
      this.fixedFundExistingValue !== +(this.fundAllocationForm.get(this.fixedAccoundFieldId)?.value)) {
      this.userService.setIsFixedOrGovtFundUpdated(true);
    }
  }

  private updateExistingValueForFunds(): void {
    this.govtFundExistingValue = +(this.fundAllocationForm.get(this.govtFundFieldId)?.value);
    this.fixedFundExistingValue = +(this.fundAllocationForm.get(this.fixedAccoundFieldId)?.value);
    this.userService.setIsFixedOrGovtFundUpdated(false);
  }

  private checkForMITab(): void {
    const selectedProduct = this.productService.getProductName()?.toLowerCase();
    if (((selectedProduct === ProductCodes.ASSETEDGEEXEC2020 || selectedProduct === ProductCodes.ASSETEDGEVUL2020) &&
      this.checkIfAnyIndexedAccountFundSelected(['FundSelect-131', 'FundSelect-130', 'FundSelect-132', 'FundSelect-133']))
      || ((selectedProduct === ProductCodes.ASSETEDGEVUL2022 || selectedProduct === ProductCodes.ASSETEDGEVUL2025 || 
        selectedProduct === ProductCodes.ASSETEDGESVUL) &&
        this.checkIfAnyIndexedAccountFundSelected(['FundSelect-139', 'FundSelect-136', 'FundSelect-130', 'FundSelect-132']))) {
      this.isMIRequired.emit('yes');
    } else {
      this.isMIRequired.emit('no');
    }
  }

  private checkIfAnyIndexedAccountFundSelected(fundIds: string[]): boolean {
    let isIndexedAccountFundSelected = false;
    this.fundAllocationData.forEach(ques => {
      if (fundIds.includes(ques.notes) && +(ques.question_answer) > 0 && !isIndexedAccountFundSelected) {
        isIndexedAccountFundSelected = true;
      }
    });
    return isIndexedAccountFundSelected;
  }

  private emitDcaInfoUpdatedInfo(fieldId: string): void {
    const productSelected = this.productService.getProductName()?.toLowerCase();
    if (this.isDCAPageApplicableForProduct(productSelected)) {
      this.isDCARequired = this.fundAllocationForm.get(fieldId)?.value === 'Y' ? 'yes' : 'no';
      this.dcaInfoUpdated.emit(this.isDCARequired);
    }
  }

  private checkForOptionalTabs(): void {
    const dcaFieldId = this.getFieldIdByXmlTag(FundAllocationConst.dollarCostAverage);
    this.emitDcaInfoUpdatedInfo(dcaFieldId);
    this.checkForMITab();
  }

  private getPortFolioFundValues(): void {
    this.fundAllocationData.forEach(ques => {
      if (ques.subText === 'PORTFOLIO_TABLE') {
        this.passivePortFolioDataList = this.getPortFolioDataList(ques.questionText);
      }
    });
    this.populateFundLabels();
  }

  private getPortFolioDataList(portFolioData: string): any[] {
    const tempList = [];
    const portFolioDataRawList = portFolioData.split(',');
    portFolioDataRawList.forEach(obj => {
      const portFolioDataRaw = obj.split('=');
      const portFolioDataRawPercent = portFolioDataRaw[1].split(':');
      tempList.push({
        fundCode: portFolioDataRaw[0],
        conservative: portFolioDataRawPercent[0],
        moderate: portFolioDataRawPercent[1],
        growth: portFolioDataRawPercent[2],
        aggressiveGrowth: portFolioDataRawPercent[3],
      });
    });
    return tempList;
  }

  private populateFundLabels(): void {
    this.passivePortFolioDataList.forEach(fund => {
      this.populateFundLabelFromQuesData(this.fundAllocationDataCombined, fund);
    });
  }

  private populateFundLabelFromQuesData(quesData: Question[], fund): void {
    quesData.forEach(ques => {
      if (ques.notes === 'FundSelect-' + fund.fundCode) {
        fund.fundLabel = ques.questionText;
      }
    });
  }

  private initializePortfolio(): void {
    this.passivePortFolioQuesId = this.fundAllocationData.filter(ques => {
      return ques.subText === 'PORTFOLIO_SELECTION';
    })[0]?.fieldId;
    this.selectedAllocation = this.fundAllocationForm.get(this.passivePortFolioQuesId)?.value;
    if (this.selectedAllocation) {
      this.selectedPortfolio = 'passive';
    }
    this.setOptionIndexForPortfolioTable();
    if (this.selectedPortfolio) {
      this.disableIndividualFundSelectOption();
    }
  }

  private disableIndividualFundSelectOption(): void {
    this.updateFundSelectionFieldStatus(true);
  }

  private updateFundSelectionFieldStatus(isDisable: boolean, isResetRequired?: boolean): void {
    Object.keys(this.fundAllocationForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.fundQuesXmlTag) > -1) {
        if (isResetRequired) {
          this.fundAllocationForm.get(key).reset();
        } else {
          if (isDisable) {
            this.fundAllocationForm.get(key).reset();
            this.fundAllocationForm.get(key).disable();
          } else {
            this.fundAllocationForm.get(key).enable();
          }
        }
      }
    });
  }

  private setOptionIndexForPortfolioTable(): void {
    if (this.selectedAllocation?.indexOf('Conservative') > -1) {
      this.optionIndex = 1;
    } else if (this.selectedAllocation?.indexOf('Moderate') > -1) {
      this.optionIndex = 2;
    } else if (this.selectedAllocation?.indexOf('AggGrowth') > -1) {
      this.optionIndex = 4;
    } else if (this.selectedAllocation?.indexOf('Growth') > -1) {
      this.optionIndex = 3;
    } else {
      this.optionIndex = -1;
    }
  }

  private reinitializeModalPortfolioVal(): void {
    this.optionIndex = -1;
    this.selectedAllocation = undefined;
  }
}
