import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DisableStyle } from '@ng/lfg-calendar';
import { Condition, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { basicDobValidator, rateAndDobValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, Message, PrimaryInsuredConstant, ProposedInsuredBConstant } from 'src/config/constants';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-proposed-insured-b',
  templateUrl: './proposed-insured-b.component.html',
  styleUrls: ['./proposed-insured-b.component.scss']
})
export class ProposedInsuredBComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  proposedInsuredBData: Question[] = [];
  proposedInsuredBForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  setTimeFocus = null;
  alldropDownOptions = {};
  calculatedAge: number;
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date of Birth',
    disableStyle: DisableStyle.locked,
  };
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  stateDiffAlertMessage = Message.DIFF_PROPOSED_INSURED_STATE_INFO;
  readonly PRIMARYCONT = PrimaryInsuredConstant;
  readonly ProposedInsuredXMLTags = ProposedInsuredBConstant;
  insuredaddressFieldIds = [];

  phoneInfo = Message.PHONE_INFO;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    public utilsService: UtilsService,
    private commonService: CommonService,
    private userService: UserDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private productService: ProductDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.initializeFieldIdsForSameAsInsured();
      this.updateDobValidator();
      this.updateRateAndDobValidator();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.proposedInsuredBForm);
      this.defaultPageLoadService.disableFormIfLocked(this.proposedInsuredBForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.updateAddressSameAsOwner(
        this.proposedInsuredBForm,
        this.proposedInsuredBForm.get(this.getFieldIdByXmlTag(ProposedInsuredBConstant.addressSameAsInsured)) as FormControl,
        ProposedInsuredBConstant.addressSameAsInsured);
    }
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private buildFormData(): any {
    this.proposedInsuredBData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.proposedInsuredBData
    );
    const form = this.formgeneratorService.createFormControls(
      this.proposedInsuredBData
    );
    this.proposedInsuredBForm = this.fb.group(form);
    this.valueChanges();
    setTimeout(() => {
      this.makeFieldsNonEditable();
    }, 100);
  }

  private makeFieldsNonEditable(): any {
    const fieldsToBeNonEditable = this.appConfig.proposedInsuredBDisableFieldKey;
    for (const key of fieldsToBeNonEditable) {
      const formKey = this.utilsService.getFieldId(this.proposedInsuredBData, key);
      if (formKey) {
        this.proposedInsuredBForm.get(formKey).disable();
      }
    }
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.proposedInsuredBForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.proposedInsuredBForm,
      data: null,
    };
    for (const data of this.proposedInsuredBData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'proposedInsuredBForm',
      isFormChange: this.proposedInsuredBForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.proposedInsuredBForm),
    };
    this.formStatus.emit(obj);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  handleSelectionChange(data, _ix?): any {
    this.updateRateAndDobValidator();
    this.updatedAnswersOnHidden(data);
  }

  getIsSameAddress(fieldId: string): any {
    const stateValue = this.proposedInsuredBForm.get(fieldId)?.value;
    if (stateValue) {
      return (this.utilsService.getCodeFromValue(stateValue?.value) === this.userService.getInsuredState());
    }
  }

  updatedAnswersOnHidden(data): any {
    const value = this.proposedInsuredBForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.proposedInsuredBForm.get(key)?.markAsUntouched();
      }
      this.proposedInsuredBForm.patchValue(updatedval);
    }
  }

  private calculateAge(): void {
    this.proposedInsuredBForm.get(this.getQuestionIdByXmlTag(ProposedInsuredBConstant.dobQuestionId))?.valueChanges.subscribe((value) => {
      if (value) {
        this.calculatedAge = this.utilsService.calculateAge(value);
        this.proposedInsuredBForm.patchValue({
          [this.getQuestionIdByXmlTag(ProposedInsuredBConstant.ageQuestId)]:
            this.calculatedAge.toString()
        });
      } else {
        this.proposedInsuredBForm.patchValue({ [this.getQuestionIdByXmlTag(ProposedInsuredBConstant.ageQuestId)]: 0 });
      }
      this.updateRateAndDobValidator();
    });
  }

  private getQuestionIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  validateOnFocus(data, type): any {
    this.setTimeFocus = setTimeout(() => {
      if (type === 'in') {
        this.proposedInsuredBForm.get(data.fieldId)?.markAsUntouched();
      } else {
        this.proposedInsuredBForm.get(data.fieldId)?.markAsTouched();
      }
      this.calculateAge();
    }, 10);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.proposedInsuredBForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckProposedInsuredInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  updateAddressSameAsOwner(proposedInsuredForm: FormGroup, isAddressSame: FormControl, xmlTag: string, selectionChanged?: boolean): void {
    const address = this.userService.getInsuredAddress();
    if (address && isAddressSame?.value?.value?.indexOf('Insured') > -1) {
      Object.keys(proposedInsuredForm.controls).forEach((contrl) => {
        if (this.insuredaddressFieldIds.includes(contrl)) {
          this.updateAddressBasedOnControl(contrl, proposedInsuredForm, address);
        }
      });
    } else if (selectionChanged) {
      this.resetAddress(proposedInsuredForm, xmlTag);
    }
  }

  private updateAddressBasedOnControl(contrl: any, proposedInsuredForm: FormGroup, address: any): void {
    if (contrl?.indexOf('/Address1') > -1) {
      proposedInsuredForm.get(contrl).setValue(address?.address1);
    } else if (contrl?.indexOf('/Address2') > -1) {
      proposedInsuredForm.get(contrl).setValue(address?.address2);
    } else if (contrl?.indexOf('/Address3') > -1) {
      proposedInsuredForm.get(contrl).setValue(address?.address3);
    } else if (contrl?.indexOf('/City') > -1) {
      proposedInsuredForm.get(contrl).setValue(address?.city);
    } else if (contrl?.indexOf('/Address/State') > -1) {
      proposedInsuredForm.get(contrl).setValue(this.getStateValueByCode(address?.state));
    } else if (contrl?.indexOf('/AddressType') > -1) {
      proposedInsuredForm.get(contrl).setValue(this.getAddressTypeCode(this.userService.getInsuredAddressType()));
    } else if (contrl?.indexOf('/PostalCode') > -1) {
      proposedInsuredForm.get(contrl).setValue(address?.zipCode);
    } else if (contrl?.indexOf('/Address/Country') > -1) {
      proposedInsuredForm.get(contrl).setValue(this.getCountryValueByCode(address?.country));
    }
  }

  private getAddressTypeCode(addressTypeCode: string): any {
    let res: any;
    if (addressTypeCode) {
      Object.keys(this.alldropDownOptions).forEach((option) => {
        if (option.indexOf('/Address/AddressType') > -1) {
          this.alldropDownOptions[option].forEach(addressType => {
            if (addressType.value === addressTypeCode) {
              res = addressType;
            }
          });
        }
      });
      return res;
    }
  }

  private getCountryValueByCode(countryCode: string): any {
    let res: any;
    if (countryCode) {
      Object.keys(this.alldropDownOptions).forEach((option) => {
        if (option.indexOf('/Address/Country') > -1) {
          this.alldropDownOptions[option].forEach(country => {
            if (country.value.indexOf(countryCode) > -1) {
              res = country;
            }
          });
        }
      });
      return res;
    }
  }

  private getStateValueByCode(stateCode: string): any {
    let res: any;
    if (stateCode) {
      Object.keys(this.alldropDownOptions).forEach((option) => {
        if (option.indexOf('/Address/State') > -1) {
          this.alldropDownOptions[option].forEach(state => {
            if (state.value.indexOf(stateCode) > -1) {
              res = state;
            }
          });
        }
      });
      return res;
    }
  }

  private resetAddress(proposedInsuredForm: FormGroup, xmlTag: string): any {
    if (xmlTag.indexOf(ProposedInsuredBConstant.addressSameAsInsured) > -1) {
      Object.keys(proposedInsuredForm.controls).forEach((contrl) => {
        if (contrl?.indexOf('/Address1') > -1 || contrl?.indexOf('/Address2') > -1
          || contrl?.indexOf('/Address3') > -1 || contrl?.indexOf('/City') > -1
          || contrl?.indexOf('/Address/State') > -1 || contrl?.indexOf('/AddressType') > -1
          || contrl?.indexOf('/PostalCode') > -1 || contrl?.indexOf('/Address/Country') > -1) {
          proposedInsuredForm.get(contrl).reset();
        }
      });
    }
  }

  private initializeFieldIdsForSameAsInsured(): void {
    this.proposedInsuredBData.forEach((ques) => {
      if (ques.xmlTag === ProposedInsuredBConstant.addressSameAsInsured && ques.hasReflexive === 'true') {
        ques.reflexiveQuestionAL.forEach((refQues) => {
          if (refQues.optionChoice?.toLowerCase().indexOf('insured') > -1) {
            this.insuredaddressFieldIds.push(refQues.fieldId);
          }
        });
      }
    });
  }

  private updateDobValidator(): void {
    if (this.productService.getListOfProduct()?.length > 0 && this.productService.getProductName()) {
      const selectedProductConditions = this.productService.getListOfProduct().filter(
        product => product.productName?.toLowerCase() === this.productService.getProductName()?.toLowerCase())[0]?.productDetail?.condition;
      this.addDobValidator(selectedProductConditions);
    }
  }

  private addDobValidator(productCondition: Condition): void {
    const dateOfBirthId = this.getFieldIdByXmlTag(ProposedInsuredBConstant.dobQuestionId);
    this.proposedInsuredBForm.get(dateOfBirthId)?.setValidators([basicDobValidator(productCondition?.termMinAge,
      productCondition?.termMaxAge)]);
    this.proposedInsuredBForm.get(dateOfBirthId)?.updateValueAndValidity();
  }

  private updateRateAndDobValidator(): void {
    // adding the validator only for SVUL, once we get confirmation, remove if condition
    if (this.productService.getProductName()?.toLowerCase() === ProductCodes.ASSETEDGESVUL) {
      const age = this.proposedInsuredBForm.get(this.getFieldIdByXmlTag(ProposedInsuredBConstant.ageQuestId))?.value;
      const rateClassId = this.getFieldIdByXmlTag(ProposedInsuredBConstant.rateClass);
      this.proposedInsuredBForm.get(rateClassId)?.setValidators(
        [rateAndDobValidator(this.productService.getProductCondition()?.ageConditions,
          this.userService.getInsuredState(),
          age),
        Validators.required]);
      this.proposedInsuredBForm.get(rateClassId)?.updateValueAndValidity();
    }
  }
  
  onCheckboxChange(data: Question): any {
    this.updatedAnswersOnHidden(data);
  }
}
