<section class="proposed-insuredInfo-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Proposed Insured B Information
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="proposedInsuredBForm" id="proposedInsuredBForm" method="post" *ngIf="proposedInsuredBData && proposedInsuredBData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of proposedInsuredBData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information'">
            <h2>{{ data.questionText }}
              <lfg-tooltip *ngIf="data.tooltip && data.questionText?.toLowerCase() === 'contact information'" class="tooltip-container">{{phoneInfo}}</lfg-tooltip>
              <lfg-tooltip *ngIf="data.tooltip && data.questionText?.toLowerCase() !== 'contact information'" class="tooltip-container">{{data.tooltip}}</lfg-tooltip>
            </h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width multi-checkbox checkbox-rider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
            <div class="lfg-checkbox">
              <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [checked]="data.value == 'true'" [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
              <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
            </div>
            <lfg-alert-message *ngIf="proposedInsuredBForm.get(data.fieldId).invalid" type="error">{{ getErrorMsg(data, proposedInsuredBForm.get(data.fieldId)) }}</lfg-alert-message>
          </div>

          <!-- Form element for SSN -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='proposedInsuredBForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur [required]="data.required == 'true'">

              <lfg-error *ngIf="(proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid) && ( proposedInsuredBForm.get(data.fieldId).errors == null || (proposedInsuredBForm.get(data.fieldId).errors != null && !proposedInsuredBForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>

              <lfg-error *ngIf="(proposedInsuredBForm.get(data.fieldId).invalid && proposedInsuredBForm.get(data.fieldId).touched) &&(proposedInsuredBForm.get(data.fieldId).errors != null && proposedInsuredBForm.get(data.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid),
          'question-radio--small': data.xmlTag == ProposedInsuredXMLTags.genderQuestionId, 'question-radio--medium': data.xmlTag == ProposedInsuredXMLTags.primaryPhoneTypeQuestionId
          || data.xmlTag == ProposedInsuredXMLTags.secondaryPhoneTypeQuestionId, 'radio-header': data.xmlTag == ProposedInsuredXMLTags.drivingLicenseNoneCheckedQuestionId ||
          data.xmlTag == ProposedInsuredXMLTags.USCitizenIndQuestionId}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form Element for date  -->
          <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
            <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="proposedInsuredBForm.get(data.fieldId)" [config]="dateConfig" [class.calendar--err]="(proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
              <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid && !proposedInsuredBForm.get(data.fieldId).errors?.dobLimitError" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid && proposedInsuredBForm.get(data.fieldId).errors?.dobLimitError" message="{{appConfig.errorMessages.BASIC_DOB_ERROR}}"></lfg-error>
            </lfg-calendar>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind); updateAddressSameAsOwner(proposedInsuredBForm,proposedInsuredBForm.get(data.fieldId),data.xmlTag, true)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, proposedInsuredBForm.get(data.fieldId)) }}"></lfg-error>

          </div>

          <!-- Form element for phone number -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

              <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="proposedInsuredBForm.get(data.fieldId).touched && proposedInsuredBForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && proposedInsuredBForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let proposedChildL1 of data.reflexiveQuestionAL; index as proposedChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckProposedInsuredInfo(data, proposedChildL1, parentAnswer)">

                <!-- USA address -->
                <div class="questions-header" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                  <h2>{{ proposedChildL1.questionText }}</h2>
                </div>

                <!-- Questions headers -->
                <div class="questions-header" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.HDR  && proposedChildL1.questionText != 'Line'">
                  <h2>{{ proposedChildL1.questionText }}</h2>
                </div>

                <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                <div class="questionText" *ngIf="addCorrectPlaceHolder(proposedChildL1, 'display')">
                  {{ addCorrectPlaceHolder(proposedChildL1, 'question') }}
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && proposedChildL1.xmlTag == ProposedInsuredXMLTags.driverLicenseNumberQuestionId && proposedChildL1.notes != 'ITES_FORMAT_AS_ZIPCODE'">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(proposedChildL1) }}" placeholder="{{ addCorrectPlaceHolder(proposedChildL1, 'placeHolder') }}" type='text' formControlName='{{ proposedChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="alphaNumericWithAsterisk" [maxlength]="proposedChildL1.maxLength" [required]="proposedChildL1.required == 'true'">
                    <lfg-error *ngIf="proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid" message="{{ getErrorMsg(proposedChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && proposedChildL1.xmlTag != ProposedInsuredXMLTags.driverLicenseNumberQuestionId && proposedChildL1.notes != 'ITES_FORMAT_AS_ZIPCODE'">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(proposedChildL1) }}" placeholder="{{ addCorrectPlaceHolder(proposedChildL1, 'placeHolder') }}" type='text' formControlName='{{ proposedChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="proposedChildL1.maxLength" [required]="proposedChildL1.required == 'true'">
                    <lfg-error *ngIf="proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid" message="{{ getErrorMsg(proposedChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Zip  -->
                <div class="form--control" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && proposedChildL1.notes == 'ITES_FORMAT_AS_ZIPCODE'">
                  <lfg-input-wrap>
                    <input lfgInput mask='00000-0000' aria-live="off" name="{{ displayTextLabel(proposedChildL1) }}" placeholder="{{ addCorrectPlaceHolder(proposedChildL1, 'placeHolder') }}" type='text' formControlName='{{ proposedChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="number" maxlength="10" [required]="proposedChildL1.required == 'true'">
                    <lfg-error *ngIf="proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid" message="{{ getErrorMsg(proposedChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>
                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(proposedChildL1) }}" placeholder="{{ addCorrectPlaceHolder(proposedChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ proposedChildL1.fieldId }}' autocomplete="off" appValidateOnBlur value="" [maxlength]="proposedChildL1.maxLength" [required]="proposedChildL1.required == 'true'">

                    <lfg-error *ngIf="proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid" message="{{ getErrorMsg(proposedChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': proposedChildL1.questionText.length > 32}">
                  <span class="screen-reader-only" *ngIf="proposedChildL1.xmlTag === ProposedInsuredXMLTags.stateOfBirthQuestionId">{{ proposedChildL1.questionText }}</span>
                  <lfg-dropdown formControlName="{{ proposedChildL1.fieldId }}" id="{{displayIdCreation(proposedChildL1)}}_{{proposedChildL1ix}}" [options]="alldropDownOptions[proposedChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(proposedChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(proposedChildL1, proposedChildL1ix)" [required]="proposedChildL1.required == 'true'" [ngClass]="{'dropdown--err': (proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="proposedInsuredBForm.get(proposedChildL1.fieldId).touched && proposedInsuredBForm.get(proposedChildL1.fieldId).invalid" message="{{ getErrorMsg(proposedChildL1) }}"></lfg-error>
                </div>


                <!-- proposed insured state info -->
                <div class="question-info" *ngIf="proposedChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT && proposedChildL1.xmlTag === PRIMARYCONT.addressState && (getIsSameAddress(proposedChildL1.fieldId) === false)">
                  <lfg-alert-message type="info">{{ stateDiffAlertMessage }}</lfg-alert-message>
                </div>

              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </form>
    </section>
  </div>
</section>
