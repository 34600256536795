import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, FundAllocationConst, Message, TransAllocationConst } from 'src/config/constants';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-transfer-allocation-charges-authorization',
  templateUrl: './transfer-allocation-charges-authorization.component.html',
  styleUrls: ['./transfer-allocation-charges-authorization.component.scss']
})
export class TransferAllocationChargesAuthorizationComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  TransferAlloConst = TransAllocationConst;
  transferAllocationData = [];
  transferAllocationForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  maxFundError = Message.MAX_FUND_ERROR;
  fundMissingError = Message.FUND_MISSING_ERROR;

  FundConst = FundAllocationConst;
  selectedFundList: any[] = [];
  deductionFundList: any[] = [];
  isDeductFundValid: boolean;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.updateFundRelatedVal();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.transferAllocationForm);
      this.defaultPageLoadService.disableFormIfLocked(this.transferAllocationForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  buildFormData(): any {
    this.transferAllocationData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.transferAllocationData
    );
    this.transferAllocationForm = this.fb.group(form);
    this.valueChanges();
    if (this.disableForm && this.transferAllocationForm) {
      this.transferAllocationForm.disable();
    }
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.transferAllocationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  checkFormStatus(isFundUpdated?: boolean): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.transferAllocationForm,
      data: null,
    };
    for (const data of this.transferAllocationData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.updateFundSelectedForDeduction();
    this.isFundsRequired();
    const obj = {
      formValid: this.formValid && this.isDeductFundValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'transferAllocationForm',
      isFormChange: this.transferAllocationForm.dirty || isFundUpdated || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.transferAllocationForm)
    };
    this.formStatus.emit(obj);
  }

  onCheckboxChange(data: Question, eventVal: any): any {
    if (data.xmlTag === FundAllocationConst.deductFundCheckboxXml && !eventVal?.target?.checked) {
      this.clearFunds();
    }
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.transferAllocationForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.transferAllocationForm.get(key)?.markAsUntouched();
      }
      this.transferAllocationForm.patchValue(updatedval);
    }
  }


  nestedQuestionCheckTransAll(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes') {
        return parent === true;
      } else {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  private isFundsRequired(): void {
    const isDeductFundCheckboxSelected =
      this.transferAllocationForm.get(this.getFieldIdByXmlTag(FundAllocationConst.deductFundCheckboxXml))?.value;
    this.isDeductFundValid = !this.checkIfFundSelectionAvailable() ||
      (this.checkIfFundSelectionAvailable() && !isDeductFundCheckboxSelected) ||
      (this.checkIfFundSelectionAvailable() && isDeductFundCheckboxSelected &&
        this.deductionFundList?.length > 0 && this.deductionFundList?.length <= 5);
  }

  private updateSelectedFundList(): void {
    this.transferAllocationData.forEach(ques => {
      if (ques.hasReflexive === 'true') {
        ques.reflexiveQuestionAL.forEach(reflQues => {
          if (reflQues.xmlTag === FundAllocationConst.fundCodeQuesId) {
            reflQues.questionOption?.forEach(opt => {
              if (this.userService.getSelectedFundList()?.includes(opt.value)) {
                this.selectedFundList.push({
                  desc: opt.description,
                  value: opt.value,
                  isChecked: this.deductionFundList.includes(opt.value)
                });
              }
            });
          }
        });
      }
    });
  }

  private clearFunds(): void {
    this.deductionFundList = [];
    this.selectedFundList.forEach(fund => {
      fund.isChecked = false;
    });
  }

  onFundSelect(fundCode: string, eventVal: any): void {
    if (eventVal?.target?.checked) {
      this.deductionFundList.push(fundCode);
    }
    else {
      this.deductionFundList.splice(this.deductionFundList.indexOf(fundCode), 1);
    }
    this.checkFormStatus(true);
  }

  private fetchFundSelectedForDeduction(): void {
    this.fetchOrUpdateFundSelectedForDeduction(true);
  }

  private updateFundSelectedForDeduction(): void {
    if (this.deductionFundList?.length > 0 && this.checkIfFundSelectionAvailable()) {
      this.fetchOrUpdateFundSelectedForDeduction(false);
    }
  }

  private fetchOrUpdateFundSelectedForDeduction(isFetch: boolean): void {
    this.transferAllocationData.forEach(ques => {
      if (ques.hasReflexive === 'true') {
        ques.reflexiveQuestionAL.forEach(reflQues => {
          if (reflQues.xmlTag === FundAllocationConst.fundCodeQuesId) {
            if (isFetch && reflQues.question_answer) {
              this.deductionFundList = reflQues.question_answer?.split('~~');
            } else {
              reflQues.question_answer = this.deductionFundList.join('~~');
            }
          }
        });
      }
    });
  }

  private checkIfFundSelectionAvailable(): boolean {
    const selectedProduct = this.productService.getProductName()?.toLowerCase();
    return selectedProduct === ProductCodes.ASSETEDGEVUL2022 || selectedProduct === ProductCodes.ASSETEDGEVUL2020 ||
      selectedProduct === ProductCodes.ASSETEDGEEXEC2020 || selectedProduct === ProductCodes.ASSETEDGEVUL2025 ||
      selectedProduct === ProductCodes.ASSETEDGESVUL;
  }

  private updateFundRelatedVal(): void {
    if (this.checkIfFundSelectionAvailable()) {
      this.fetchFundSelectedForDeduction();
      this.updateSelectedFundList();
    }
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }
}
