/** Business validators start here */

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ESignatureInstructions, Message, RiderQuestionConstant } from 'src/config/constants';
import { AgeCondition, Condition } from '../models/casePage.model';
import { ProductCodes } from 'src/config/sideNav.config';

/**
 * sales material validator for state - UT - Exisiting insurance
 * @param isSalesMaterialUsed - is sales material
 * @returns ValidatorFn
 */
export function saleMaterialValidator(isSalesMaterialUsed: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    return !isSalesMaterialUsed && !value ? { requiredError: true } : null;
  };
}

/**
 * Validate Death Benefit Options - Rider page
 * @returns ValidatorFn
 */
export function dboValidator(formControl: AbstractControl, productCode: string, dboValue?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    productCode = productCode?.toLowerCase();
    if (!formControl?.parent || !value ||
      (productCode !== ProductCodes.WEALTHACCUMULATEIUL2020 && productCode !== ProductCodes.ASSETEDGEVUL2025 &&
        productCode !== ProductCodes.ASSETEDGESVUL)) {
      return null;
    }
    if (productCode === ProductCodes.WEALTHACCUMULATEIUL2020) {
      let requiredRiderSelected = false;
      for (const key of Object.keys(formControl.parent.controls)) {
        if (key.indexOf(RiderQuestionConstant.CCABRQuesId) > -1 && formControl.parent.get(key).value) {
          requiredRiderSelected = true;
        }
      }
      return value?.label?.toLowerCase() === 'increase by premium' && requiredRiderSelected ? { dboError: true } : null;
    } else if (productCode === ProductCodes.ASSETEDGEVUL2025 || productCode === ProductCodes.ASSETEDGESVUL) {
      return value?.label?.toLowerCase() === 'increase by premium' && dboValue?.indexOf('cvat') > -1 ? { dboPremiumError: true } : null;
    }
  };
}

/**
 * Validator for duration depending on proposed age (other rider & primary insured rider) - Rider page
 * @returns ValidatorFn
 */
export function riderAgeAndDurationValidator(proposedAge: number, productCode: string, fieldXmlTag: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const duration = control?.value ? +(control.value.label) : null;
    if (!duration || duration < 1 || !proposedAge) {
      return null;
    }
    productCode = productCode?.toLowerCase();
    let error;
    if (productCode !== ProductCodes.WEALTHACCUMULATEIUL2020) {
      const errorMessage = fieldXmlTag === RiderQuestionConstant.OtherRiderDuration ?
        Message.OTHER_RIDER_DURATION_ERROR : Message.PRIMARY_RIDER_DURATION_ERROR;
      error = +proposedAge + duration > 80 ? { ageRiderError: { errorMessage: errorMessage.replace('{{age}}', '80') } } : null;
    } else {
      error = updateErrorForAge(fieldXmlTag, proposedAge, duration);
    }
    return error;
  };
}

function updateErrorForAge(fieldXmlTag: string, proposedAge: number, duration: number): any {
  let error = null;
  if (fieldXmlTag === RiderQuestionConstant.OtherRiderDuration) {
    error = +proposedAge + duration > 100 ?
      { ageRiderError: { errorMessage: Message.OTHER_RIDER_DURATION_ERROR.replace('{{age}}', '100') } } : null;
  } else {
    if (duration === 10) {
      error = getErrorByAgeAndDuration(18, 69, proposedAge, '10', Message.PRIMARY_RIDER_DURATION_ERROR_WA);
    } else if (duration === 15) {
      error = getErrorByAgeAndDuration(18, 59, proposedAge, '15', Message.PRIMARY_RIDER_DURATION_ERROR_WA);
    } else if (duration === 20) {
      error = getErrorByAgeAndDuration(18, 49, proposedAge, '20', Message.PRIMARY_RIDER_DURATION_ERROR_WA);
    }
  }
  return error;
}

function getErrorByAgeAndDuration(minAge: number, maxAge: number, proposedAge: number, duration: string, errorMessage: string): any {
  return proposedAge < minAge || proposedAge > maxAge ?
    {
      ageRiderError: { errorMessage: errorMessage.replace('{{age}}', maxAge.toString()).replace('{{duration}}', duration) }
    } : null;
}
/**
 * Validator for Other Rider Amount - Riders page
 * @returns ValidatorFn
 */
export function faceAmountAndOtherRiderAmountValidator(faceAmount: any, primaryRiderAmount: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const riderAmount = control?.value ? +(control.value) : null;
    if (!riderAmount) {
      return null;
    }
    if (isNaN(primaryRiderAmount)) {
      primaryRiderAmount = 0;
    }
    if (isNaN(faceAmount)) {
      faceAmount = 0;
    }
    faceAmount = +faceAmount;
    primaryRiderAmount = +primaryRiderAmount;
    let error = null;
    if (riderAmount < 100000) {
      error = { riderAmountError: { errorMessage: Message.RIDER_MIN_AMOUNT_ERROR } };
    } else if (riderAmount > (faceAmount + primaryRiderAmount) || riderAmount > 500000) {
      error = {
        riderAmountError: {
          errorMessage: Message.OTHER_RIDER_MAX_AMOUNT_ERROR
            .replace('{{minAmount}}', faceAmount + primaryRiderAmount)
        }
      };
    }
    return error;
  };
}

/**
 * Validator for primary Rider amount - Riders page
 * @returns ValidatorFn
 */
export function faceAmountAndPrimaryRiderAmountValidator(faceAmount: any, productCode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const riderAmount = control?.value ? +(control.value) : null;
    if (!riderAmount) {
      return null;
    }
    let error = null;
    if (riderAmount < 100000) {
      error = { riderAmountError: { errorMessage: Message.RIDER_MIN_AMOUNT_ERROR } };
    } else {
      faceAmount = +faceAmount;
      if (productCode !== ProductCodes.WEALTHACCUMULATEIUL2020) {
        error = getErrorForFaceAmount(Message.PRIMARY_RIDER_MAX_AMOUNT_ERROR, faceAmount, faceAmount, riderAmount);
      }
    }
    return error;
  };
}

function getErrorForFaceAmount(errorMessage: string, minAmount: number, faceAmount: number, riderAmount: number): string {
  let error = null;
  if ((faceAmount > 0 && riderAmount > minAmount) || (riderAmount > 1000000)) {
    error = { riderAmountError: { errorMessage } };
  }
  return error;
}

/**
 * Validator for min max Amount - mostly used in Riders page
 * @returns ValidatorFn
 */
export function minMaxAmountValidator(minAmount: number, maxAmount: number, xmlTag: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const amount = control && control.value ? +(control.value) : null;
    if (!amount) {
      return null;
    }
    let error = null;
    let minError = Message.MIN_AMOUNT_ERROR.replace('{{minAmount}}', minAmount.toString());
    let maxError = Message.MAX_AMOUNT_ERROR.replace('{{maxAmount}}', maxAmount.toString());
    if (xmlTag === RiderQuestionConstant.CareCoverageLTCSpecAmount || xmlTag === RiderQuestionConstant.LongTermLTCSpecAmount) {
      minError = maxError = Message.LTC_AMOUNT_ERROR;
    }
    if (amount < minAmount) {
      error = { riderAmountError: { errorMessage: minError } };
    } else if (amount > maxAmount) {
      error = { riderAmountError: { errorMessage: maxError } };
    }
    return error;
  };
}

/**
 * Validator for adding error depending upon selected owner type - rider page
 * @returns ValidatorFn
 */

export function typeOfOwnerValidator(
  ownerTypeSelected: string[], validateFor: string[],
  xmlTag: string, isInsuredOwner: boolean, invalidFor?: string[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const checkboxSelected = control?.value ? +(control.value) : null;
    if (!checkboxSelected) {
      return null;
    }
    if (isInsuredOwner && xmlTag === RiderQuestionConstant.IndividualExecRider) {
      return null;
    } else if (isInsuredOwner && xmlTag === RiderQuestionConstant.BusinessExecRider) {
      return { ownerTypeError: { errorMessage: Message.BUSINESS_SURRENDER_RIDER_ERROR } };
    }
    let error = null;
    const isValidOwnerSelected = validateFor.some(item => ownerTypeSelected?.includes(item));
    let isInvalidOwnerSelected = false;
    if (invalidFor?.length > 0) {
      isInvalidOwnerSelected = invalidFor.some(item => ownerTypeSelected?.includes(item));
    }
    if (!isValidOwnerSelected || isInvalidOwnerSelected || (!ownerTypeSelected || (ownerTypeSelected && ownerTypeSelected.length === 0))) {
      error = {
        ownerTypeError: {
          errorMessage: xmlTag === RiderQuestionConstant.BusinessExecRider
            ? Message.BUSINESS_SURRENDER_RIDER_ERROR : Message.INDIVIDUAL_SURRENDER_RIDER_ERROR
        }
      };
    }
    return error;
  };
}

export function stateValidator(solicitationState: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value || !solicitationState) {
      return null;
    }
    const stateSelected = value.value?.substring(0, value.value?.indexOf('{'));
    return stateSelected?.toLowerCase() !== solicitationState.toLowerCase() ? { stateError: true } : null;
  };
}

export function checkForNoOfDigits(minDigit: number, maxDigit: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.length < minDigit || value.length > maxDigit) ? { noOfDigitError: true } : null;
  };
}

export function checkForAlphaNumeric(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.match('^[A-Za-z]+$') || value.match('^[0-9]+$')) ? { textError: true } : null;
  };
}

export function checkForGreaterThanZero(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value < 1) ? { textError: true } : null;
  };
}

export function attestValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.toLowerCase() === 'no{47}no' || value.toLowerCase() === 'no'
      || value.toLowerCase().indexOf('do not') !== -1) ? { attestError: true } : null;
  };
}

export function basicDobValidator(minAge: number, maxAge: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (minAge === null || maxAge === null) {
      return null;
    } else if (!value) {
      return { dobError: true };
    }
    const age = Math.floor(Math.abs(Date.now() - new Date(value).getTime()) / (1000 * 3600 * 24) / 365);
    return age < minAge || age > maxAge ? { dobLimitError: true } : null;
  };
}

export function rateDurationAndDobValidator(ageConditions: AgeCondition[], duration: string, age: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value.label : null;
    if (!value || !duration || !ageConditions) {
      return null;
    }
    const tobaccoStatus = value?.toLowerCase();
    let error = null;
    ageConditions.forEach(ageCond => {
      const ageCondTobaccoList = ageCond.tabaccoStatusList.map(e => e.toLowerCase());
      if (ageCond.durationList.map(String).includes(duration) &&
        (ageCond.tabaccoStatusList.length === 0 || ageCondTobaccoList.includes(tobaccoStatus))) {
        if (age < ageCond.minAge || age > ageCond.maxAge) {
          error = { termAgeError: true };
        }
      }
    });
    return error;
  };
}

export function rateAndDobValidator(ageConditions: AgeCondition[], state: string, age: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value.label : null;
    if (!value || !state || !ageConditions) {
      return null;
    }
    let error = null;
    const tobaccoStatus = value.toLowerCase();
    ageConditions.forEach(ageCond => {
      const ageCondTobaccoList = ageCond.tabaccoStatusList.map(e => e.toLowerCase());
      if ((ageCond.stateList.includes(state) || ageCond.stateList.length === 0) && (!ageCond.excludedStateList.includes(state)) &&
        (ageCond.tabaccoStatusList.length === 0 || ageCondTobaccoList.includes(tobaccoStatus))) {
        if (age < ageCond.minAge || age > ageCond.maxAge) {
          error = { nonTermAgeError: true };
        }
      }
    });
    return error;
  };
}

export function faceAmountValidator(conditions: Condition): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? Math.round(control.value) : null;
    if (!value || !conditions) {
      return null;
    }
    return value < conditions.minFaceAmount || value > conditions.maxFaceAmount ? { faceAmountError: true } : null;
  };
}

export function advancePremiumAmountValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value : null;
    return !value || value <= 0 || !Number(value) ? { advancePremiumAmountError: true } : null;
  };
}

export function modalAmountValidator(premiumMode: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control && control.value ? control.value : null;
    if (!value || !premiumMode) {
      return null;
    }
    const errorMessage = Message.MODAL_PREMIUM_ERROR.replace('{mode}', premiumMode);
    if (premiumMode.toLowerCase().indexOf('semi-annual') > -1) {
      return checkForPremiumVal(100, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('annual') > -1) {
      return checkForPremiumVal(200, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('quarterly') > -1) {
      return checkForPremiumVal(50, value, errorMessage);
    } else if (premiumMode.toLowerCase().indexOf('monthly') > -1) {
      return checkForPremiumVal(15, value, errorMessage);
    } else {
      return null;
    }
  };
}

function checkForPremiumVal(minValue: number, value: number, errorMessage: string): any {
  return value < minValue ? { premiumError: { errorMessage: errorMessage.replace('{minimum}', minValue.toString()) } } : null;
}

export function yesNoValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value.toLowerCase().indexOf('no') > -1) ? { notSelectedError: true } : null;
  };
}

export function dcaValidator(isVULProduct: boolean, fixedFundPerc: number, govetFuncPerc: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    if (isVULProduct) {
      // 105 is for fixed account and 876 is for govenment fund
      if ((value === '105' && fixedFundPerc > 0) ||
        (value === '876' && govetFuncPerc > 0)) {
        return { dcaError: { errorMessage: Message.VUL_DCA_ERROR } };
      }
    } else {
      if (value === '105' && isFundUnavailable(fixedFundPerc)) {
        return { dcaError: { errorMessage: Message.ASSETEDGE_FIXED_ACCOUNT_ERROR } };
      } else if (value === '876' && isFundUnavailable(govetFuncPerc)) {
        return { dcaError: { errorMessage: Message.ASSETEDGE_GOVT_ERROR } };
      }
    }
    return null;
  };
}

function isFundUnavailable(fund: number): boolean {
  return !fund || fund === 0;
}

export function equalAnswerValidator(existingAnswer: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return ((value === 'Yes{47}Yes' && !existingAnswer) || (value === 'No{47}No' && existingAnswer))
      ? { noSameAnswer: { errorMessage: Message.REPLACEMENT_QUES_ERROR } } : null;
  };
}

export function confirmEmailValidator(email: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control ? control.value : null;
    if (!value) {
      return null;
    }
    return (value?.toLowerCase() !== email?.toLowerCase())
      ? { confirmEmailError: ESignatureInstructions.EmailError } : null;
  };
}
