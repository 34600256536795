<section class="agent-Info-cont-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Agent Information Continued
    </h1>
  </div>
  <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
  <section>
    <form class="form-group" [formGroup]="agentInfoContForm" id="agentInfoContForm" method="post" *ngIf="agentInfoContData && agentInfoContData.length > 0">
      <ng-container class="form-control-parent" *ngFor="let data of agentInfoContData; let ind = index;">
        <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Button' && data.subText != 'Frame' && data.subText != 'Message'">
          <h2>{{ data.questionText }}</h2>
        </div>

        <div class="question-paragraph" *ngIf="data.subText == 'Message' && data.display">
          <p>{{ data.questionText }}</p>
        </div>

        <div class="questions-header frame" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Frame' && data.display">
          <h2>{{ data.questionText }}</h2>
        </div>

        <!--Line text-->
        <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && data.display" [ngClass]="{'extra-padding': data.fieldId == AgentContConst.businessLineFieldId}">
        </div>

        <!-- Informational text -->
        <div class="question-info" aria-live="assertive" *ngIf="(data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.subText == 'Information' && data.fieldId !== AgentContConst.childRiderInfoId) && (((data.fieldId === AgentContConst.caseManagerInfoQuesId || data.fieldId === AgentContConst.officeStaffInfoQuesId) && showCaseAttestRadioButton) || (data.fieldId != AgentContConst.officeStaffInfoQuesId && data.fieldId != AgentContConst.caseManagerInfoQuesId))">
          <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
        </div>

        <!-- Informational text -->
        <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.subText == 'Information' && data.fieldId == AgentContConst.childRiderInfoId && showChildRiderInfo">
          <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
        </div>

        <!-- Form element for currency  -->
        <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.display && data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1">
          <lfg-input-wrap>
            <input lfgInput aria-live="off" placeholder="{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength" [required]="data.required == 'true'">
            <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoContForm.get(data.fieldId)) }}"></lfg-error>
          </lfg-input-wrap>
        </div>

        <!-- Form element for text  -->
        <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.display && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)">
          <lfg-input-wrap>
            <input lfgInput placeholder="{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
            <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoContForm.get(data.fieldId)) }}"></lfg-error>
          </lfg-input-wrap>
        </div>

        <!-- Form element for email  -->
        <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL && data.display">
          <lfg-input-wrap>
            <input lfgInput placeholder="{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'" (input)="checkToShowAttestRadioButton(data)">

            <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
          </lfg-input-wrap>
        </div>

        <div class="add-grid-button" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Button' && data.display">
          <div *ngIf="gridInstance?.length > 0">
            <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [addAccordionText]="'Add a business insurance'" [maxGridAvailable]="maxNoOfBusiness" [accordionHeader]="'Business Insurance'" [type]="'INSURANCE'" [isRevisit]="isTabVisited" [disableForm]="disableForm"></app-grid-structure>
          </div>
        </div>

        <!-- Form element for radio question -->
        <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.questionOption[0].description?.length <= 20 && data.display && ((data.xmlTag === AgentContConst.officeStaffDoYouAttestQuesId && showCaseAttestRadioButton) || data.xmlTag != AgentContConst.officeStaffDoYouAttestQuesId)" [ngClass]="{'radio--err': (agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid), 'no-text-radio': data.xmlTag == AgentContConst.eInterviewQuesId }">
          <span class="lfg-radio-section">
            <p class="question-radio_text" *ngIf="data.questionText != '(Blank)'">{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}</p>
            <div class="radio-btn-rect custom-radio-button">
              <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onAgentContRadioChange(data)" />
              <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
              <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onAgentContRadioChange(data)">
              <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
            </div>
          </span>
          <!-- As the error message contains few words in bold so adding custom error message -->
          <div *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid && !agentInfoContForm.get(data.fieldId).errors?.noSameAnswer" aria-live="polite">
            <span class="cust-form-field-err" [innerHTML]="getErrorMsg(data, agentInfoContForm.get(data.fieldId))"></span>
          </div>
          <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid && agentInfoContForm.get(data.fieldId).errors?.noSameAnswer" message="{{ agentInfoContForm.get(data.fieldId).errors?.noSameAnswer?.errorMessage }}"></lfg-error>
        </div>

        <!-- Form element for radio question having longer desc -->
        <div aria-live="assertive" class="lfg-customRadio-field-long" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.questionOption[0].description?.length > 20" [ngClass]="{'radio--err': (agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid)}">
          <span class="radio-question-container">
            <p class="question-radio_text">{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}</p>
            <div class="radio-button-container">
              <label class="radio-input-label" [ngClass]="{'radio-button-checked': agentInfoContForm.get(data.fieldId).value === data.questionOption[0].value}">
                <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="agentInfoContForm.get(data.fieldId).value === data.questionOption[0].value"></i>
                <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="agentInfoContForm.get(data.fieldId).value !== data.questionOption[0].value"></i>
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onAgentContRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
              </label>
              <label class="radio-input-label" [ngClass]="{'radio-button-checked': agentInfoContForm.get(data.fieldId).value === data.questionOption[1].value}">
                <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="agentInfoContForm.get(data.fieldId).value === data.questionOption[1].value"></i>
                <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="agentInfoContForm.get(data.fieldId).value !== data.questionOption[1].value"></i>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onAgentContRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </label>
              <label class="radio-input-label" [ngClass]="{'radio-button-checked': agentInfoContForm.get(data.fieldId).value === data.questionOption[2]?.value}" *ngIf="data.questionOption[2]">
                <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="agentInfoContForm.get(data.fieldId).value === data.questionOption[2].value"></i>
                <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="agentInfoContForm.get(data.fieldId).value !== data.questionOption[2].value"></i>
                <input [attr.aria-label]="data.questionOption[2].description" tabindex="0" id="none_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[2].value }}" required="{{data.required}}" (change)="onAgentContRadioChange(data)">
                <label for="none_{{ data.fieldId }}">{{ data.questionOption[2].description }}</label>
              </label>
            </div>
          </span>
          <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, agentInfoContForm.get(data.fieldId)) }}"></lfg-error>
        </div>

        <!-- Form Element for Inline Dropdown  -->
        <div class="form--control " aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.display" [ngClass]="{'inline-ques': data.questionText.length > 27 }">
          <div class="inline-ques-container">
            <span *ngIf="data.questionText.length > 27" class="inline-ques-text">{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}</span>
            <ng-container *ngIf="data.questionText.length > 27">
              <div class="form-dropDown inline-dropdown">
                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" (selectionChanged)="handleAgentSelectionChange(data, true)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid)}">
                </lfg-dropdown>
                <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </div>
            </ng-container>
            <ng-container *ngIf="data.questionText.length < 27">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addAgentContCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(data, true)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid)}">
              </lfg-dropdown>
              <lfg-error *ngIf="agentInfoContForm.get(data.fieldId).touched && agentInfoContForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </ng-container>
          </div>
        </div>

        <!-- Form element for check box  -->
        <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.display">
          <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data); checkIfReasonSelectedErrorExists()">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
          </div>
        </div>

        <!-- Nested question Level 1 for showing info text-->
        <div *ngIf="data.hasReflexive == 'true' && data.display &&  (data.xmlTag == AgentContConst.eInterviewQuesId || data.xmlTag == AgentContConst.existingInsuranceInfo || data.xmlTag == AgentContConst.replacementInfo) && agentInfoContForm.get(data.fieldId).value as parentAnswer">
          <ng-container *ngFor="let agentInfoContChild1 of data.reflexiveQuestionAL; index as agentInfoChild1ix">
            <ng-container *ngIf="nestedQuestionAgentContCheck(data, agentInfoContChild1, parentAnswer)">

              <!-- Informational text -->
              <div class="question-info" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.HDR && agentInfoContChild1.subText == 'Information' && agentInfoContChild1.optionChoice?.indexOf('eInterview') === -1">
                <lfg-alert-message type="info">{{ agentInfoContChild1.questionText }}</lfg-alert-message>
              </div>


              <div class="question-info" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.HDR && agentInfoContChild1.subText == 'Information' && agentInfoContChild1.optionChoice?.indexOf('eInterview') > -1">
                <lfg-alert-message type="info" *ngIf="eInterviewInfo">{{ eInterviewInfo }}</lfg-alert-message>
                <lfg-alert-message type="info" *ngIf="!eInterviewInfo">{{ agentInfoContChild1.questionText }}</lfg-alert-message>
              </div>

            </ng-container>
          </ng-container>
        </div>

        <!-- Nested question Level 1 -->
        <div *ngIf="data.hasReflexive == 'true' && data.display && data.xmlTag != AgentContConst.eInterviewQuesId && data.xmlTag != AgentContConst.existingInsuranceInfo && data.xmlTag != AgentContConst.replacementInfo && agentInfoContForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
          <ng-container *ngFor="let agentInfoContChild1 of data.reflexiveQuestionAL; index as agentInfoChild1ix">
            <ng-container *ngIf="nestedQuestionAgentContCheck(data, agentInfoContChild1, parentAnswer)">

              <div class="questions-header" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.HDR && agentInfoContChild1.questionText != 'Line' && agentInfoContChild1.subText != 'Information' && agentInfoContChild1.subText != 'Button' && agentInfoContChild1.subText != 'Frame' && agentInfoContChild1.subText != 'Message' && agentInfoContChild1.subText != 'Alert'">
                <p><strong>{{ agentInfoContChild1.questionText }}</strong></p>
              </div>

              <!-- Informational text -->
              <div class="question-info" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.HDR && agentInfoContChild1.subText == 'Information'">
                <lfg-alert-message type="info">{{ agentInfoContChild1.questionText }}</lfg-alert-message>
              </div>

              <!-- Alert text -->
              <div class="question-info" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.HDR && agentInfoContChild1.subText == 'Alert'">
                <lfg-alert-message type="error">{{ agentInfoContChild1.questionText }}</lfg-alert-message>
              </div>

              <!-- Form element for text  -->
              <div class="form--control" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!agentInfoContChild1.notes || agentInfoContChild1.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoContChild1) }}" placeholder="{{ addAgentContCorrectPlaceHolder(agentInfoContChild1, 'placeHolder') }}" type='text' formControlName='{{ agentInfoContChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(agentInfoContChild1.answerTextType)" [maxlength]="agentInfoContChild1.maxLength" [required]="agentInfoContChild1.required == 'true'">
                  <lfg-error *ngIf="agentInfoContForm.get(agentInfoContChild1.fieldId).touched && agentInfoContForm.get(agentInfoContChild1.fieldId).invalid" message="{{getErrorMsg(agentInfoContChild1)}}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for currency  -->
              <div class="form--control" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (agentInfoContChild1.notes && agentInfoContChild1.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" placeholder="{{ addAgentContCorrectPlaceHolder(agentInfoContChild1, 'placeHolder') }}" type='text' formControlName='{{ agentInfoContChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(agentInfoContChild1)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="agentInfoContChild1.maxLength" [required]="agentInfoContChild1.required == 'true'">
                  <lfg-error *ngIf="agentInfoContForm.get(agentInfoContChild1.fieldId).touched && agentInfoContForm.get(agentInfoContChild1.fieldId).invalid" message="{{ getErrorMsg(agentInfoContChild1, agentInfoContForm.get(agentInfoContChild1.fieldId)) }}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for Drop Down -->
              <div class="form--control form-dropDown" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.SELECT">
                <lfg-dropdown formControlName="{{ agentInfoContChild1.fieldId }}" id="{{displayIdCreation(agentInfoContChild1)}}_{{ind}}" [options]="alldropDownOptions[agentInfoContChild1.fieldId]" placeholder="{{ addAgentContCorrectPlaceHolder(agentInfoContChild1, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(agentInfoContChild1)" [required]="agentInfoContChild1.required == 'true'" [ngClass]="{'dropdown--err':(agentInfoContForm.get(agentInfoContChild1.fieldId).touched && agentInfoContForm.get(agentInfoContChild1.fieldId).invalid)}">
                </lfg-dropdown>
                <lfg-error *ngIf="agentInfoContForm.get(agentInfoContChild1.fieldId).touched && agentInfoContForm.get(agentInfoContChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
              </div>

              <!-- Form element for text area -->
              <div class="form--control form--control__full-width" aria-live="assertive" *ngIf="agentInfoContChild1.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
                <lfg-input-wrap>
                  <input lfgInput placeholder="{{ agentInfoContChild1.questionText}}" name="{{ displayTextLabel(agentInfoContChild1) }}" aria-live="off" type='text' formControlName='{{ agentInfoContChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="agentInfoContChild1.maxLength" [required]="agentInfoContChild1.required">
                  <span class="text-area-count">{{agentInfoContForm.get(agentInfoContChild1.fieldId).value.length}}/{{agentInfoContChild1.maxLength}}</span>
                  <lfg-error *ngIf="agentInfoContForm.get(agentInfoContChild1.fieldId).touched && agentInfoContForm.get(agentInfoContChild1.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Level 2 reflexive ques  -->
              <div *ngIf="agentInfoContChild1.hasReflexive == 'true' && agentInfoContForm.get(agentInfoContChild1.fieldId).value as parentAnswer1">
                <ng-container *ngFor="let agentInfoContChild2 of agentInfoContChild1.reflexiveQuestionAL; index as agentInfoChild2ix">
                  <ng-container *ngIf="nestedQuestionAgentContCheck(agentInfoContChild1, agentInfoContChild2, parentAnswer1)">

                    <!-- Form element for text  -->
                    <div class="form--control" aria-live="assertive" *ngIf="agentInfoContChild2.controlTypeDesc == appConfig.fieldTypes.TEXT">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoContChild2) }}" placeholder="{{ addAgentContCorrectPlaceHolder(agentInfoContChild2, 'placeHolder') }}" type='text' formControlName='{{ agentInfoContChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="agentInfoContChild2.maxLength" [required]="agentInfoContChild2.required == 'true'">
                        <lfg-error *ngIf="agentInfoContForm.get(agentInfoContChild2.fieldId).touched && agentInfoContForm.get(agentInfoContChild2.fieldId).invalid" message="{{getErrorMsg(agentInfoContChild2)}}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                  </ng-container>
                </ng-container>
              </div>

            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </section>
</section>
