import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { dcaValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, FundAllocationConst, Message } from 'src/config/constants';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';


@Component({
  selector: 'app-dollar-cost-averaging',
  templateUrl: './dollar-cost-averaging.component.html',
  styleUrls: ['./dollar-cost-averaging.component.scss']
})
export class DollarCostAveragingComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  dollarCostAveragingData: Question[] = [];
  dollarCostAveragingData2: Question[] = [];
  dollarCostAveragingForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  ErrMessage = ErrorMessage;

  dollarCostAverageId: string;
  fixedAccountFund = 0;
  govtAccountFund = 0;
  totalFundPercentage = 0;
  openAccordion = false;
  isMoneyMarketIsSelected;
  govtFundFieldId;

  showDollarCostFundInfo: boolean;

  FundConst = FundAllocationConst;
  Message = Message;
  isVULOneProduct = false;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private utilsService: UtilsService,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private productService: ProductDetailsService,
    private userService: UserDetailsService) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.isVULOneProduct = this.isVULProduct();
      this.intializeRequiredValues();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.dollarCostAveragingForm);
      this.defaultPageLoadService.disableFormIfLocked(this.dollarCostAveragingForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.dollarCostAveragingData = this.questionsData.pages[0].questions;
    this.dollarCostAveragingData2 = this.isAssetEdgeProduct() ? this.questionsData.pages[1]?.questions : [];
    this.updateFieldIds();
    const form = this.formgeneratorService.createFormControls(
      this.dollarCostAveragingData.concat(this.dollarCostAveragingData2)
    );
    this.dollarCostAveragingForm = this.fb.group(form);
    this.valueChanges();
  }

  private updateValuesAndAddValidations(): void {
    this.disableDCARadioButton();
    this.checkForAccountToDCAError();
    this.updateDefaultValues(true);
    this.updateIfMoneyMarketIsSelected();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.calculatetotalFundPercentage();
      this.updateValuesAndAddValidations();
      this.checkForDollarCostFundInfo();
      this.checkFormStatus();
    }, 200);
    this.dollarCostAveragingForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.calculatetotalFundPercentage();
        this.checkForDollarCostFundInfo();
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.dollarCostAveragingForm,
      data: null,
    };
    for (const data of this.dollarCostAveragingData.concat(this.dollarCostAveragingData2)) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid && ((!this.isVULOneProduct && this.totalFundPercentage === 100) || this.isVULOneProduct),
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'dollarCostAveragingForm',
      isFormChange: this.dollarCostAveragingForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.dollarCostAveragingForm),
    };
    this.formStatus.emit(obj);
  }

  onRadioChange(data: Question): any {
    this.checkForAccountToDCAError();
    this.updateDefaultValues(false);
    this.updateIfMoneyMarketIsSelected();
    this.updateAnswersOnHidden(data);
  }

  toggleAccordionStatus(): void {
    this.openAccordion = !this.openAccordion;
  }

  updateAnswersOnHidden(data: Question): any {
    const value = this.dollarCostAveragingForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.dollarCostAveragingForm.get(key)?.markAsUntouched();
      }
      this.dollarCostAveragingForm.patchValue(updatedval);
    }
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  checkIfQuestionDisplayed(data: Question): any {
    const answer = this.dollarCostAveragingForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckDCAInfo(data: Question, childData: Question, parent: any): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  private disableDCARadioButton(): void {
    if (this.isAssetEdgeProduct()) {
      const dollarCostAvgQuesId = this.getFieldIdByXmlTag(FundAllocationConst.dollarCostAverage);
      this.dollarCostAveragingForm.get(dollarCostAvgQuesId)?.setValue('Y');
      this.dollarCostAveragingForm.get(dollarCostAvgQuesId)?.disable();
    }
  }

  private checkForDollarCostFundInfo(): void {
    if (this.isVULProduct()) {
      if (this.isDollarCostAveragingSelected()) {
        this.showDollarCostFundInfo = this.fixedAccountFund > 0 && this.govtAccountFund > 0;
      } else {
        this.showDollarCostFundInfo = false;
      }
    }
  }

  private intializeRequiredValues(): void {
    this.dollarCostAverageId = this.getFieldIdByXmlTag(FundAllocationConst.dollarCostAverage);
    this.fixedAccountFund = this.userService.getFixedAccountFund();
    this.govtAccountFund = this.userService.getGovtFund();
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private calculatetotalFundPercentage(): void {
    this.totalFundPercentage = 0;
    if (!this.isVULOneProduct) {
      Object.keys(this.dollarCostAveragingForm.controls).forEach(key => {
        if (key.indexOf(FundAllocationConst.dcaFundQuesXmlTag) > -1) {
          this.updatePercentage(key);
          this.totalFundPercentage = this.totalFundPercentage + +(this.dollarCostAveragingForm.get(key).value);
        }
      });
    }
  }

  private updatePercentage(key: string): void {
    if (+(this.dollarCostAveragingForm.get(key)?.value > 100)) {
      this.dollarCostAveragingForm.get(key).setValue('100');
    }
  }

  private checkForAccountToDCAError(): void {
    if (this.isDollarCostAveragingSelected()) {
      const accountToDCA = this.getFieldIdByXmlTag(FundAllocationConst.accountToDCA);
      this.dollarCostAveragingForm.get(accountToDCA).setValidators([Validators.required,
      dcaValidator(this.isVULProduct(), this.fixedAccountFund, this.govtAccountFund)]);
      this.dollarCostAveragingForm.get(accountToDCA).updateValueAndValidity();
    }
  }

  private isAssetEdgeProduct(): boolean {
    const productSelected = this.productService.getProductName()?.toLowerCase();
    return (productSelected === ProductCodes.ASSETEDGEEXEC2020 || productSelected === ProductCodes.ASSETEDGEVUL2020
      || productSelected === ProductCodes.ASSETEDGEVUL2022 || productSelected === ProductCodes.ASSETEDGEVUL2025 
      || productSelected === ProductCodes.ASSETEDGESVUL);
  }

  private isVULProduct(): boolean {
    const productSelected = this.productService.getProductName()?.toLowerCase();
    return (productSelected === ProductCodes.SVULONE2021
      || productSelected === ProductCodes.VULONE2021);
  }

  private isDollarCostAveragingSelected(): boolean {
    return this.dollarCostAveragingForm.get(this.dollarCostAverageId)?.value?.toLowerCase() === 'y';
  }

  private updateIfMoneyMarketIsSelected(): void {
    this.isMoneyMarketIsSelected = this.dollarCostAveragingForm.get(this.getFieldIdByXmlTag(
      FundAllocationConst.accountToDCA))?.value === '876';
    if (this.isMoneyMarketIsSelected) {
      this.dollarCostAveragingForm.get(this.govtFundFieldId)?.reset();
      this.dollarCostAveragingForm.get(this.govtFundFieldId)?.disable();
    } else {
      this.dollarCostAveragingForm.get(this.govtFundFieldId)?.enable();
    }
  }

  private updateDefaultValues(onPageload: boolean): void {
    if (this.isDollarCostAveragingSelected()) {
      if (this.isVULProduct()) {
        this.updateFieldAndDisable(FundAllocationConst.dcaPeriod, true, '1', true);
        this.updateFieldAndDisable(FundAllocationConst.dcaTransferOption, true, '12', true);
      } else {
        this.updateFieldAndDisable(FundAllocationConst.dcaPeriod, false);
        this.updateFieldAndDisable(FundAllocationConst.dcaTransferOption, false);
        if (onPageload) {
          this.updateAccountToDCA();
        }
      }
    }
  }

  private updateAccountToDCA(): void {
    const isAccountToDCASelected = this.dollarCostAveragingForm.get(this.getFieldIdByXmlTag(FundAllocationConst.accountToDCA))?.value;
    if (this.fixedAccountFund > 0 && !isAccountToDCASelected) {
      this.updateFieldAndDisable(FundAllocationConst.accountToDCA, true, '105', false);
    } else if (this.govtAccountFund > 0 && !isAccountToDCASelected) {
      this.updateFieldAndDisable(FundAllocationConst.accountToDCA, true, '876', false);
    } else {
      this.updateFieldAndDisable(FundAllocationConst.accountToDCA, false);
      if (this.userService.getIsFixedOrGovtFundUpdated()) {
        this.dollarCostAveragingForm.get(this.getFieldIdByXmlTag(FundAllocationConst.accountToDCA))?.reset();
        this.userService.setIsFixedOrGovtFundUpdated(false);
      }
    }
  }

  private updateFieldAndDisable(xmlTag: string, setValue: boolean, value?: string, isDisable?: boolean): void {
    const fieldId = this.getFieldIdByXmlTag(xmlTag);
    if (setValue) {
      this.dollarCostAveragingForm.get(fieldId)?.setValue(value);
      if (isDisable) {
        this.dollarCostAveragingForm.get(fieldId)?.disable();
      }
    } else {
      this.dollarCostAveragingForm.get(fieldId)?.enable();
    }
  }

  resetForm(): void {
    Object.keys(this.dollarCostAveragingForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.dcaFundQuesXmlTag) > -1) {
        this.dollarCostAveragingForm.get(key).reset();
      }
    });
    this.defaultPageLoadService.logButtonClick('reset all selections');
  }

  private updateFieldIds(): void {
    this.govtFundFieldId = this.dollarCostAveragingData2.filter(ques => {
      return ques.notes === 'FundSelect-876';
    })[0]?.fieldId;
  }
}
